import accordion from './modules/accordion/accordion';
import accordionView from './modules/accordion/accordion.view';

export * from './modules/accordion/accordion';
export * from './modules/accordion/accordion.view';
import {IAccordionExpandedState} from './data-actions/accordion-state';
import { createAccordionStateInput } from './data-actions/accordion-state-data-action';

import { interactionStyle } from './modules/accordion/accordion.props.autogenerated';

export {
    accordion,
    accordionView,
    IAccordionExpandedState,
    createAccordionStateInput,
    interactionStyle
};