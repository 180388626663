import { Module } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { INotificationsListViewProps } from './notifications-list';

export const NotificationsListView: React.FC<INotificationsListViewProps> = props => {
    return (
        <Module {...props.container}>
            {props.notificationsList}
        </Module>
    );
};

export default NotificationsListView;